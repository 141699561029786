.animationCardArea {
    background-color: #353535;
    color: #c0c0c0;
}

.animationCardMediaThumb {
    height: 200px;
    width: 200px;
}

.animationCardMediaThumbTitle {
    font-weight: bold;
    font-size: 16px;
    filter: drop-shadow(0px 3px 5px #333)
}

.animationCardContentArea {
    height: 25px;
}